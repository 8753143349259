$('.navbar-toggle[data-toggle="collapse"]').click(function(){
	var fa = $(this).find('.fa');
	var status = fa.attr('class').replace('fa ','');
	
	$('body').toggleClass('body-fixed');
	
	if(status == 'fa-bars'){
		fa.removeClass('fa-bars').addClass('fa-close')
	}else{
		fa.removeClass('fa-close').addClass('fa-bars')
	}

	// console.log(status)
});

